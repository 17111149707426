import $ from 'jquery';

$(document).on('click', '.tocItem a', function (event) {
  event.preventDefault();
  $('html, body').animate(
    {
      scrollTop:
        $($.attr(this, 'href')).offset().top - $('header').height() - 20,
    },
    500,
  );
});

window.addEventListener('scroll', (event) => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const elHeaders = document.querySelectorAll('[data-toc]');
  let headerId = '';
  for (let i = elHeaders.length - 1; i >= 0; i--) {
    if (
      elHeaders[i].getBoundingClientRect().top + window.pageYOffset - 200 <
      scrollTop
    ) {
      headerId = elHeaders[i].id;
      break;
    }
  }
  document.querySelectorAll('.tocItem a').forEach((el) => {
    el.classList.remove('active');
  });
  if (headerId) {
    document.querySelector(`a[href$="#${headerId}"]`).classList.add('active');
  }
});
