import $ from 'jquery';

window.$ = $;
window.jQuery = $;

import './components/off-canvas';
import './components/slide-menu';
import './components/accordion';
import './components/tabs';
import './components/cookies';
import './components/header-search';
import './components/totop';
import './components/aside';
import './components/filter';
import './components/hero-togler';
import './components/iframe';
// import './components/show-animate';

// Open trigger
$('[data-open]').on('click', function () {
  const $target = $($(this).data('open'));
  $target.trigger('zefir.open', [this]);
});

// Toggle trigger
$('[data-toggle]').on('click', function () {
  const $target = $($(this).data('toggle'));
  $target.trigger('zefir.toggle', [this]);
});

// Close trigger
$('[data-close]').on('click', function () {
  const $target = $($(this).data('close'));

  if ($target) {
    $target.trigger('zefir.close', [this]);
  } else {
    $(document).trigger('zefir.close', [this]);
  }
});
