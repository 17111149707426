import $ from 'jquery';

const $filterBtns = $('.filter-list-btn button');
const $filterClea = $('.filter-btn');

$filterBtns.on('click', function (ev) {
  ev.preventDefault();
  $(this).toggleClass('is-active');
});

$filterClea.on('click', function (ev) {
  $filterBtns.removeClass('is-active');
});
