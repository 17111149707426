import $ from 'jquery';

const $iframeBtn = $('[data-iframe-btn]');

$iframeBtn.on('click', function () {
  const iframe = $(this).parents('[data-iframe]');
  const iframeLink = iframe.attr('data-iframe');
  const iframeImg = iframe.find('.iframe-img');
  iframe.toggleClass('show');
  iframeImg.html(
    `<iframe src="${iframeLink}" allow="autoplay; fullscreen; picture-in-picture"></iframe>`,
  );
});
