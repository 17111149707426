import $ from 'jquery';
import Cookies from 'js-cookie';

$('[data-cookies]').each(function () {
  const $el = $(this);
  const [name, timeout, expires] = [
    $el.data('cookies-name') || 'accept_cookie',
    $el.data('cookies-delay') || 2000,
    $el.data('cookies-expires') || 90,
  ];
  const $close = $el.find('[data-cookies-close]');
  const $button = $el.find('[data-cookies-accept]');

  $('body').append($el);

  function close() {
    $el.fadeOut(300, () => {
      $el.remove();
    });
  }

  if (!Cookies.get(name)) {
    setTimeout(() => {
      $el.fadeIn(200);
    }, timeout);
  }

  $close.on('click', close);

  $button.on('click', () => {
    Cookies.set(name, '1', { expires: expires });
    close();
  });
});
