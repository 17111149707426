import $ from 'jquery';

$('[data-tabs]').each(function () {
  const $el = $(this);
  const $buttons = $el.find('a');
  const $cont = $(`[data-tabs-content="${$el.data('tabs')}"]`);
  const $panels = $cont.children();

  $buttons.on('click', function (ev) {
    ev.preventDefault();
    const $btn = $(this);
    const $panel = $panels.filter($btn.attr('href'));

    if (!$btn.hasClass('is-active')) {
      $buttons.removeClass('is-active');
      $btn.addClass('is-active');
    }

    if (!$panel.hasClass('is-active')) {
      $panels.removeClass('is-active').hide();
      $panel.fadeIn(300).addClass('is-active');
    }
  });
});
