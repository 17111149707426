import $ from 'jquery';

const searchBtn = $('.search-point');
const searchInput = $('.search input');
const searchBox = $('.search');

searchBtn.on('click', function () {
  const el = $(this);
  searchBox.addClass('open');
  $(document).mouseup(function (e) {
    if (!searchBox.is(e.target) && searchBox.has(e.target).length === 0) {
      searchBox.removeClass('open');
    }
  });
});

searchInput.on('input', function () {
  const el = $(this);
  if (el.val() != '') {
    searchBox.addClass('has-text');
  } else {
    searchBox.removeClass('has-text');
  }
  searchBox.find('.search-close').on('click', function () {
    if ($(this).hasClass('mob-close')) {
      searchInput.val('');
      searchBox.removeClass('has-text');
    } else {
      searchBox.removeClass('open');
    }
  });
});
