import $ from 'jquery';

$('[data-off-canvas]').each(function () {
  const $el = $(this);

  function open(...target) {
    const $target = $(target[1]);
    $el.removeClass('is-close').addClass('is-open');
    $target.addClass('is-active');
    $('body').addClass('off-canvas-open');
  }

  function close(...target) {
    const $target = $(target[1]);
    $el.removeClass('is-open').addClass('is-close');
    $target.removeClass('is-active');
    $('body').removeClass('off-canvas-open');
  }

  function toggle(ev, target) {
    !$el.hasClass('is-open') ? open(ev, target) : close(ev, target);
  }

  $(document).on('zefir.close', close);
  $el
    .on('zefir.open', open)
    .on('zefir.close', close)
    .on('zefir.toggle', toggle);
});
