import $ from 'jquery';

const $heroToggle = $('[data-toggle]');

$heroToggle.on('click', function () {
  let text = $(this).attr('data-toggle');
  $(this).attr('data-toggle', $(this).find('.hero-brand-toggle-text').html());
  $(this).parent('.hero-brand-inner').toggleClass('open');
  $(this).find('.hero-brand-toggle-text').html(text);
  $(this).toggleClass('open');
});
